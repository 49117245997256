





















































import { Component, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';

@Component
export default class CustomDropdown extends AbstractField {
  items: DropdownItem[] = []
  localItem: any = []
  loading: boolean = false
  activeItemsOnly: boolean = this.field.activeItemsOnly
  dropdownOpen: boolean = false
  selectedItem: DropdownItem | null = null
  customItem: string | null = null

  @Watch('value') onValueChange(newVal: string) {
    if (newVal !== this.selectedItem?.title) {
      this.loadData(newVal)
    }
  }

  created(): void {
    this.field.onCreated && this.field.onCreated(this.form, this.field.index, this.group, this.rowIndex)
  }

  loadData(query: string = ''): void {
    this.loading = true
    this.items = []

    http
      .get(`${this.field.loadItemsProps.endpoint}?q=${query}&per_page=999`)
      .then((response: IResponse<IModelResponse>) => {
        this.items = response.data.data
      })
      .finally((): void => {
        this.loading = false
      })
  }

  openDropdown() {
    this.dropdownOpen = true
    // eslint-disable-next-line no-unused-expressions
    document.getElementById(this.field.key)?.focus()
    if (!this.value || this.value !== this.selectedItem?.title) {
      this.loadData(this.value || undefined)
    }
  }

  itemClick(item: DropdownItem) {
    this.dropdownOpen = false
    this.blurSelection()
    if (this.selectedItem?.id === item.id) {
      this.selectedItem = null
      this.value = null
      return
    }
    this.value = item.title
    this.selectedItem = item
    this.field.dataOnChange &&
    this.field.dataOnChange(
      this.form,
      this.field.index,
      this.selectedItem,
      this.group,
      this.loading
    )
    this.field.onChange && this.field.onChange(this.form, this.field.index, this.group, this.rowIndex)
  }

  setCustomItem() {
    if (this.value === this.customItem) {
      this.value = null
      this.customItem = null
    } else {
      this.selectedItem = null
      this.customItem = this.value
      this.field.onChange && this.field.onChange(this.form, this.field.index, this.group, this.rowIndex)
    }
    this.dropdownOpen = false
    this.blurSelection()
  }

  blurSelection() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  get showCustomItem(): boolean {
    return this.value && this.selectedItem?.title !== this.value
  }
}

interface DropdownItem {
  id: number
  title: string,
  price_per_unit: string,
  vat: number,
  amount: string,
  price_per_unit_with_vat: string,
  unit: string
}

